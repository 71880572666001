<template>
  <div class="app-container text-center">
    <h1>
      {{ $t('page_not_found.title') }}
    </h1>
    <v-button size="large" type="primary" @click="goToMainPage">
      {{ $t('page_not_found.btn_main_page') }}
    </v-button>
  </div>
</template>

<script>
import VButton from '../../components/ui/v-button/VButton'
import {MAIN_PAGE_ROUTE_NAME} from '../../shared/constants/app-routes-names'

export default {
  name: 'PageNotFound',
  components: {VButton},
  methods: {
    goToMainPage() {
      this.$router.push({name: MAIN_PAGE_ROUTE_NAME})
    }
  }
}
</script>

<style scoped>

</style>
